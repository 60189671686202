html, body, #root {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Source Sans Pro", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f7f7f7;
}

.pre-content {
  white-space: pre;
}

footer.privacy-policy {
  display: flex;
  position: fixed;
  bottom: 0;
  right: 0;
  height: 22px;
  padding: 0 8px;
  border-top-left-radius: 6px;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,0.4);
}

footer.privacy-policy>a {
  color: #fff;
  font-size: 11px;
}